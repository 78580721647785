import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography, Stack } from '@mui/material';
import Page from '#/components/shared/ui/Page';
import Image from '#/components/shared/ui/Image';

export default function Maintenance() {
  return (
    <Stack
      sx={{
        py: 12,
        maxWidth: 400,
        textAlign: 'center',
        m: 'auto',
        minHeight: '100vh',
        justifyContent: 'center',
      }}
    >
      <Page title="Maintenance">
        <Stack sx={{ alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Website currently under maintenance
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            We are currently working hard on this page!
          </Typography>
          <Image
            sx={{ my: { xs: 5, sm: 10 } }}
            src="/assets/icons/maintenance.svg"
          />
          <Button
            component={RouterLink}
            to="/"
            size="large"
            variant="contained"
          >
            Go to Home
          </Button>
        </Stack>
      </Page>
    </Stack>
  );
}
